import { graphql, useStaticQuery } from "gatsby"

export const useStaticQueryDataChatMutuo = () => {
	const {
		wp: {
			acfOptionsChatmutuo: { chatMutuo },
		},
	} = useStaticQuery(graphql`
		query {
			wp {
				acfOptionsChatmutuo {
					chatMutuo {
						wizard {
							abilita
							steps {
								contenuto
							}
							ultimoStep {
								ctaLabel
							}
							actionButtons {
								labelButtonSkip
								labelButtonNext
							}
						}
						logo {
							id
							altText
							localFile {
								publicURL
								extension
								childImageSharp {
									gatsbyImageData(quality: 100, layout: FULL_WIDTH)
								}
							}
							height
							width
							title
						}
						logoMobile {
							id
							altText
							localFile {
								publicURL
								extension
								childImageSharp {
									gatsbyImageData(quality: 100, layout: FULL_WIDTH)
								}
							}
							height
							width
							title
						}
						assistente {
							avatar {
								id
								altText
								localFile {
									publicURL
									extension
									childImageSharp {
										gatsbyImageData(quality: 100, layout: FULL_WIDTH)
									}
								}
								height
								width
								title
							}
							avatarMobile {
								id
								altText
								localFile {
									publicURL
									extension
									childImageSharp {
										gatsbyImageData(quality: 100, layout: FULL_WIDTH)
									}
								}
								height
								width
								title
							}
							claim
						}
						chat {
							assistente {
								nome
								avatar {
									id
									altText
									localFile {
										publicURL
										extension
										childImageSharp {
											gatsbyImageData(quality: 100, layout: FULL_WIDTH)
										}
									}
									height
									width
									title
								}
							}
							primoMessaggio
							links {
								titolo
								elementi {
									tipologia
									question
									link {
										url
										title
										target
									}
								}
							}
							inputPlaceholder
						}
					}
				}
			}
		}
	`)
	return {
		chatMutuo,
	}
}
