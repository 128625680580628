import { useMediaQuery } from "react-responsive"

// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px,
//     // xxl: 1400px
// );

// $container-max-widths: (
//     // sm: 540px,
//     // md: 720px,
//     lg: 960px,
//     xl: 1140px,
//     // xxl: 1320px
// );

// https://getbootstrap.com/docs/5.2/layout/breakpoints/

export const useMobileMediaQuery = () =>
	useMediaQuery({ query: "(max-width: 575.98px)" })

export const useDesktopMediaQuery = () =>
	useMediaQuery({ query: "(min-width: 767.98px)" })

export const useLargeMediaQuery = () =>
	useMediaQuery({ query: "(min-width: 991.98px)" })

export const useLargeAndBelowMediaQuery = () =>
	useMediaQuery({ query: "(max-width: 991.98px)" })

export const useTabletAndBelowMediaQuery = () =>
	useMediaQuery({ query: "(max-width: 767.98px)" })

export const useOnlyMdMediaQuery = () =>
	useMediaQuery({ query: "(min-width: 767.98px) and (max-width: 991.98px)" })

export const useOnlyLgMediaQuery = () =>
	useMediaQuery({ query: "(min-width: 991.98px) and (max-width: 1199.98px)" })

export const Mobile = ({ children }) => {
	const isMobile = useMobileMediaQuery()
	return isMobile ? children : null
}

export const Tablet = ({ children }) => {
	const isTablet = useOnlyMdMediaQuery()
	return isTablet ? children : null
}

export const TabletAndBelow = ({ children }) => {
	const isTabletAndBelow = useTabletAndBelowMediaQuery()
	return isTabletAndBelow ? children : null
}

export const Desktop = ({ children }) => {
	const isDesktop = useDesktopMediaQuery()
	return isDesktop ? children : null
}
