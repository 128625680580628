import { Link as GatsbyLink } from "gatsby"
import React from "react"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const UniversalLink = ({
	children,
	to,
	target,
	className,
	activeClassName,
	partiallyActive,
	iubenda,
	...other
}) => {
	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to)
	// Use Gatsby Link for internal links, and <a> for others

	// console.log("this is iubenda")
	// console.log(iubenda)
	if (internal) {
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				className={className}
				target={target}
				// data-iub-enabled={iubenda}
				{...other}
			>
				{children}
			</GatsbyLink>
		)
	}

	return (
		<a href={to} target={target} 
			className={className} 
			// data-iub-enabled={iubenda} 
			{...other}>
			{children}
		</a>
	)
}
export default UniversalLink
