import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import parse from "html-react-parser"
import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { IMaskInput } from "react-imask"
import * as yup from "yup"
import "yup-phone-lite"

import { MSMRequest } from "../../../../api/msm"
import Text from "../../../Text"
import * as ContattiStampaMediaStyles from "./contatti-stampa-media.module.scss"

const schemaContattiStampaMedia = yup.object().shape({
	Nome: yup
		.string()
		.min(2, 'Il campo "Nome" deve contenere almeno 2 caratteri')
		.trim()
		.required('Il campo "Nome" è obbligatorio')
		.matches(/^[aA-zZ\s]+$/, 'Il campo "Nome" può contenere solo lettere'),
	Cognome: yup
		.string()
		.min(2, 'Il campo "Cognome" deve contenere almeno 2 caratteri')
		.trim()
		.required('Il campo "Cognome" è obbligatorio')
		.matches(/^[aA-zZ\s]+$/, 'Il campo "Cognome" può contenere solo lettere'),
	Testata: yup
		.string()
		.min(2, 'Il campo "Testata" deve contenere almeno 2 caratteri')
		.trim()
		.required('Il campo "Testata" è obbligatorio'),
	Telefono: yup
		.string()
		.phone("IT", 'Il campo "Telefono" deve essere un numero valido')
		.required('Il campo "Telefono" è obbligatorio'),
	Email: yup.string().email().nullable().optional(),
	Note: yup.string().nullable().optional(),
	AccettaTrattamentoDati: yup
		.boolean()
		.oneOf([true], "Devi accettare la privacy policy per inviare il modulo"),
})

function ContattiStampaMedia({ labelCta, consenso, setSettingsModal }) {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		watch,
		reset,
	} = useForm({
		resolver: yupResolver(schemaContattiStampaMedia),
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: {
			Nome: "",
			Cognome: "",
			Testata: "",
			Telefono: "",
			Email: "",
			Note: "",
			AccettaTrattamentoDati: false,
		},
	})

	const watchs = watch()

	const onSubmit = async data => {
		const response = await MSMRequest("ContattoStampa", data)
		if (response.success) {
			const BodyContent = (
				<Text content="Il messaggio è stato inviato con successo. A breve sarai ricontattato." />
			)
			setSettingsModal({
				title: "Grazie",
				content: BodyContent,
			})
			reset()
		} else {
			const BodyContent = (
				<Text content="Si è verificato un errore. Riprova più tardi." />
			)
			setSettingsModal({
				title: "Attenzione",
				content: BodyContent,
			})
		}
	}

	const onError = (errors, e) => {
		const BodyContent = (
			<>
				{Object.keys(errors).map((key, index) => {
					return <p key={index}>{errors[key].message}</p>
				})}
			</>
		)
		setSettingsModal({
			title: "Attenzione",
			content: BodyContent,
		})
	}

	return (
		<Form className={ContattiStampaMediaStyles.form} autoComplete="off">
			<Row className={ContattiStampaMediaStyles.formRow}>
				<Col md={4}>
					<div
						className={classNames(ContattiStampaMediaStyles.field, {
							[ContattiStampaMediaStyles.fieldError]: errors.Nome,
							[ContattiStampaMediaStyles.fieldValid]:
								!errors.Nome && watchs.Nome?.length > 0,
						})}
					>
						<label
							htmlFor="Nome"
							className={classNames({
								[ContattiStampaMediaStyles.filled]: watchs.Nome?.length > 0,
							})}
						>
							Nome*
						</label>
						<input type="text" {...register("Nome")} />
					</div>
				</Col>
				<Col md={4}>
					<div
						className={classNames(ContattiStampaMediaStyles.field, {
							[ContattiStampaMediaStyles.fieldError]: errors.Cognome,
							[ContattiStampaMediaStyles.fieldValid]:
								!errors.Cognome && watchs.Cognome?.length > 0,
						})}
					>
						<label
							htmlFor="Cognome"
							className={classNames({
								[ContattiStampaMediaStyles.filled]: watchs.Cognome?.length > 0,
							})}
						>
							Cognome*
						</label>
						<input type="text" {...register("Cognome")} />
					</div>
				</Col>
				<Col md={4}>
					<div
						className={classNames(ContattiStampaMediaStyles.field, {
							[ContattiStampaMediaStyles.fieldError]: errors.Testata,
							[ContattiStampaMediaStyles.fieldValid]:
								!errors.Testata && watchs.Testata?.length > 0,
						})}
					>
						{" "}
						<label
							htmlFor="Testata"
							className={classNames({
								[ContattiStampaMediaStyles.filled]: watchs.Testata?.length > 0,
							})}
						>
							Testata*
						</label>
						<input type="text" {...register("Testata")} />
					</div>
				</Col>
				<Col md={4}>
					<div
						className={classNames(ContattiStampaMediaStyles.field, {
							[ContattiStampaMediaStyles.fieldError]: errors.Telefono,
							[ContattiStampaMediaStyles.fieldValid]:
								!errors.Telefono && watchs.Telefono?.length > 0,
						})}
					>
						<label
							htmlFor="Telefono"
							className={classNames({
								[ContattiStampaMediaStyles.filled]: watchs.Telefono?.length > 0,
							})}
						>
							Telefono
						</label>
						<Controller
							name="Telefono"
							control={control}
							valueAsNumber={true}
							render={({ field }) => (
								<IMaskInput
									mask={Number}
									unmask={true}
									onAccept={(value, mask) => {
										field.onChange(value)
									}}
								/>
							)}
						/>
					</div>
				</Col>
				<Col md={4}>
					<div
						className={classNames(ContattiStampaMediaStyles.field, {
							[ContattiStampaMediaStyles.fieldError]: errors.Email,
							[ContattiStampaMediaStyles.fieldValid]:
								!errors.Email && watchs.Email?.length > 0,
						})}
					>
						<label
							htmlFor="Email"
							className={classNames({
								[ContattiStampaMediaStyles.filled]: watchs.Email?.length > 0,
							})}
						>
							E-mail*
						</label>
						<input type="email" {...register("Email")} />
					</div>
				</Col>
				<Col md={12}>
					<div className={ContattiStampaMediaStyles.field}>
						<textarea {...register("Note")} placeholder="Messaggio" />
					</div>
				</Col>
				<Col md={12}>
					<div
						className={classNames(ContattiStampaMediaStyles.fieldCheckbox, {
							[ContattiStampaMediaStyles.fieldError]:
								errors?.AccettaTrattamentoDati,
						})}
					>
						<input type="checkbox" {...register("AccettaTrattamentoDati")} />
						<label htmlFor="AccettaTrattamentoDati">
							{parse(consenso.label, {
								replace: domNode => {
									if (
										domNode.attribs &&
										domNode.attribs["data-config"] &&
										domNode.attribs["data-config"].length > 0
									) {
										const dataConfigParsed = JSON.parse(
											domNode.attribs["data-config"]
										)
										switch (dataConfigParsed.type) {
											case "open-popup":
												return (
													<button
														className={ContattiStampaMediaStyles.trigger}
														onClick={e => {
															e.preventDefault()
															const BodyContent = (
																<>
																	<Text
																		content={consenso.popup.testo}
																		removeP={false}
																	/>
																</>
															)
															setSettingsModal({
																title: consenso.popup.titolo,
																content: BodyContent,
															})
														}}
													>
														{dataConfigParsed.label}
													</button>
												)
											default:
												break
										}
									}
								},
							})}
						</label>
					</div>
				</Col>
				<Col md={12}>
					<button
						type="submit"
						onClick={handleSubmit(onSubmit, onError)}
						className={ContattiStampaMediaStyles.button}
						disabled={isSubmitting}
					>
						{labelCta}
					</button>
				</Col>
			</Row>
		</Form>
	)
}

export default ContattiStampaMedia
