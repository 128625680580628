import { createReducer } from "@reduxjs/toolkit"

import {
	HIDE_MODAL_COMPARE_MUTUI,
	HIDE_MODAL_CONTATTACI,
	HIDE_MODAL_FATTIBILITA,
	HIDE_MODAL_MUTUO,
	IS_MEGA_MENU_OPEN,
	RESET_MUTUI_TO_SHOW,
	SET_MUTUI_TO_SHOW,
	SHOW_MODAL_COMPARE_MUTUI,
	SHOW_MODAL_CONTATTACI,
	SHOW_MODAL_FATTIBILITA,
	SHOW_MODAL_MUTUO,
} from "../actions/ui.actions"

export const uiReducer = createReducer(
	{
		mutuo: {
			selected: {
				show: false,
			},
			fattibilita: {
				show: false,
			},
		},
		researcherItemsToShow: {},
		comparator: {
			show: false,
		},
		megamenu: {
			open: false,
		},
		contattaci: {
			show: false,
		},
	},
	builder =>
		builder
			.addCase(SHOW_MODAL_CONTATTACI, state => {
				state.contattaci.show = true
			})
			.addCase(HIDE_MODAL_CONTATTACI, state => {
				state.contattaci.show = false
			})
			.addCase(SHOW_MODAL_FATTIBILITA, state => {
				state.mutuo.fattibilita.show = true
			})
			.addCase(HIDE_MODAL_FATTIBILITA, state => {
				state.mutuo.fattibilita.show = false
			})
			.addCase(SHOW_MODAL_COMPARE_MUTUI, state => {
				state.comparator.show = true
			})
			.addCase(HIDE_MODAL_COMPARE_MUTUI, state => {
				state.comparator.show = false
			})
			.addCase(SHOW_MODAL_MUTUO, state => {
				state.mutuo.selected.show = true
			})
			.addCase(HIDE_MODAL_MUTUO, state => {
				state.mutuo.selected.show = false
			})
			.addCase(IS_MEGA_MENU_OPEN, (state, action) => {
				state.megamenu.open = action.payload
			})
			.addCase(SET_MUTUI_TO_SHOW, (state, action) => {
				state.researcherItemsToShow = {
					...state.researcherItemsToShow,
					[action.payload.id]: action.payload.numberOfItemsToShow,
				}
			})
			.addCase(RESET_MUTUI_TO_SHOW, (state, action) => {
				state.researcherItemsToShow = {
					...state.researcherItemsToShow,
					[action.payload.id]: 6,
				}
			})
)
