import * as marginBottomComponentStyles from "./marginBottomFlexible.module.scss"

export const marginBottomComponent = layoutData => {
	if (!layoutData?.margineInferiore) {
		return marginBottomComponentStyles.marginMedium
	}
	switch (layoutData?.margineInferiore) {
		case "small":
			return marginBottomComponentStyles.marginSmall
		case "medium":
			return marginBottomComponentStyles.marginMedium
		case "big":
			return marginBottomComponentStyles.marginBig
		default:
			return marginBottomComponentStyles.marginMedium
	}
}
