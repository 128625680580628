// extracted by mini-css-extract-plugin
export var button = "marketing-affiliation-module--button--29d61";
export var checked = "marketing-affiliation-module--checked--87566";
export var field = "marketing-affiliation-module--field--f0a5b";
export var fieldCheckbox = "marketing-affiliation-module--field-checkbox--43004";
export var fieldDouble = "marketing-affiliation-module--field-double--80fd7";
export var fieldError = "marketing-affiliation-module--field-error--0806e";
export var fieldErrorClean = "marketing-affiliation-module--field-error-clean--13e7c";
export var fieldErrorDate = "marketing-affiliation-module--field-error-date--19400";
export var fieldValid = "marketing-affiliation-module--field-valid--1deec";
export var fieldValidClean = "marketing-affiliation-module--field-valid-clean--ebaec";
export var filled = "marketing-affiliation-module--filled--45c43";
export var form = "marketing-affiliation-module--form--876f0";
export var formRow = "marketing-affiliation-module--form-row--8ea4a";
export var reactSelectRichiesta__control = "marketing-affiliation-module--react-select-richiesta__control--c21f5";
export var smallText = "marketing-affiliation-module--small-text--c9482";
export var trigger = "marketing-affiliation-module--trigger--8c8f4";
export var unchecked = "marketing-affiliation-module--unchecked--2f5a5";