import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import React from "react"

const Image = ({
	data,
	useImgTag = false,
	className,
	imgClassName,
	fetchpriority = "low",
	...props
}) => {
	if (!data) {
		return null
	}

	const {
		altText,
		height,
		width,
		title,
		localFile: { extension, publicURL, childImageSharp },
	} = data

	const gatsbyImageData = getImage(childImageSharp) || null
	const alt = altText || title

	if ((extension === "svg" && !gatsbyImageData) || useImgTag) {
		const src = getSrc(childImageSharp) || publicURL

		if (!src) {
			return null
		}

		return (
			<>
				<img
					src={src}
					alt={alt}
					height={height || "auto"}
					width={width || "auto"}
					fetchpriority={fetchpriority}
					className={className}
					{...props}
				/>
			</>
		)
	}

	if (!gatsbyImageData) {
		return null
	}

	return (
		<>
			<GatsbyImage
				image={gatsbyImageData}
				alt={alt}
				className={className}
				imgClassName={imgClassName}
				fetchpriority={fetchpriority}
				{...props}
			/>
		</>
	)
}

export default Image
