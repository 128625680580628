import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Ratio from "react-bootstrap/Ratio"
import ReactPlayer from "react-player/youtube"

import Loader from "../../Loader"
import Text from "../../Text"
import * as comeFunzionaStyles from "./come-funziona.module.scss"

function ComeFunziona({
	data,
	show,
	setActiveKey,
	disableLoader = false,
	classNamePadding = "",
}) {
	const [play, setPlay] = useState(null)
	const {
		tipo,
		acfOptionsComeFunziona: { video },
	} = data
	useEffect(() => {
		if (!show) {
			setPlay(null)
		}
	}, [show])
	if (!video) {
		return null
	}
	return (
		<div
			className={classNames(comeFunzionaStyles.comeFunziona, {
				[comeFunzionaStyles.comeFunziona__show]: show,
				[comeFunzionaStyles.comeFunziona__yellow]: tipo === "SUR",
				[comeFunzionaStyles.comeFunziona__orange]: tipo === "APC",
			})}
		>
			<div
				className={classNames(
					comeFunzionaStyles.comeFunziona__padding,
					classNamePadding
				)}
			>
				<Container className={comeFunzionaStyles.comeFunziona__container}>
					<button
						onClick={() => setActiveKey(null)}
						className={comeFunzionaStyles.comeFunziona__close}
					>
						<span>Chiudi</span>
						<div>
							<svg
								width="19"
								height="19"
								viewBox="0 0 19 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.1501 4.84961L4.8501 14.1496"
									stroke="white"
									strokeWidth="1.55"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.8501 4.84961L14.1501 14.1496"
									stroke="white"
									strokeWidth="1.55"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
					</button>
					<div className={comeFunzionaStyles.comeFunziona__video_container}>
						{video?.length > 0 &&
							video.map((v, i) => {
								const { youtubeUrl = null, titolo = null, testo } = v
								const isEven = i % 2 === 0
								return (
									<Row
										className={classNames(
											comeFunzionaStyles.comeFunziona__row,
											{
												[comeFunzionaStyles.comeFunziona__row__even]: !isEven,
											}
										)}
										key={i}
									>
										{youtubeUrl && (
											<Col
												lg={6}
												xl={5}
												className={comeFunzionaStyles.comeFunziona__video_col}
											>
												<Ratio aspectRatio="16x9">
													<ReactPlayer
														url={youtubeUrl}
														width="100%"
														height="100%"
														light={true}
														className={comeFunzionaStyles.comeFunziona__video}
														config={{
															youtube: {
																playerVars: {
																	showinfo: 0,
																	controls: 2,
																},
															},
														}}
														playing={i === play}
														// onPause={() => setPlay(null)}
														onPlay={() => setPlay(i)}
														onClickPreview={() => setPlay(i)}
													/>
												</Ratio>
											</Col>
										)}
										<Col
											lg={youtubeUrl ? 6 : 12}
											xl={youtubeUrl ? 7 : 12}
											className={
												comeFunzionaStyles.comeFunziona__video_text_col
											}
										>
											{titolo && <h2>{v.titolo}</h2>}
											<div className={comeFunzionaStyles.comeFunziona__editor}>
												<Text content={testo} removeP={false} rowContent />
											</div>
										</Col>
									</Row>
								)
							})}
					</div>
					{!disableLoader && (
						<div className={comeFunzionaStyles.comeFunziona__loader}>
							<Loader noWrapperMotion noDimension />
						</div>
					)}
				</Container>
			</div>
		</div>
	)
}

export default ComeFunziona
