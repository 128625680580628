// extracted by mini-css-extract-plugin
export var button = "contatti-stampa-media-module--button--c15ce";
export var checked = "contatti-stampa-media-module--checked--1a90e";
export var field = "contatti-stampa-media-module--field--589c6";
export var fieldCheckbox = "contatti-stampa-media-module--field-checkbox--b9f2c";
export var fieldDouble = "contatti-stampa-media-module--field-double--ba117";
export var fieldError = "contatti-stampa-media-module--field-error--4109d";
export var fieldErrorClean = "contatti-stampa-media-module--field-error-clean--15f96";
export var fieldErrorDate = "contatti-stampa-media-module--field-error-date--99f9e";
export var fieldValid = "contatti-stampa-media-module--field-valid--71317";
export var fieldValidClean = "contatti-stampa-media-module--field-valid-clean--100d4";
export var filled = "contatti-stampa-media-module--filled--d93ad";
export var form = "contatti-stampa-media-module--form--addf0";
export var formRow = "contatti-stampa-media-module--form-row--84027";
export var reactSelectRichiesta__control = "contatti-stampa-media-module--react-select-richiesta__control--69f04";
export var smallText = "contatti-stampa-media-module--small-text--d622f";
export var trigger = "contatti-stampa-media-module--trigger--84323";
export var unchecked = "contatti-stampa-media-module--unchecked--31de5";