import axios from "axios"

import msm_api from "./msm-api.json"

export const auth = {
	auth: {
		username: process.env.API_AUTH_USERNAME,
		password: process.env.API_AUTH_PASSWORD,
	},
}

export const MSMRequest = async (name, payload = {}) => {
	const endpoint = msm_api.endpoint?.[name] || null
	if (!endpoint) {
		return null
	}
	const abortController = new AbortController()
	try {
		const response = await axios(
			{
				url: `${msm_api.base_url}${endpoint.url}`,
				method: endpoint.method,
				data: payload,
				headers: msm_api.headers,
				...auth,
			},
			{ signal: abortController.signal }
		)
		pushDatalayerEventIfExistInResponse(response)
		// addHtmlToBodyIfExistInResponse(response)
		return response.data
	} catch (error) {
		return null
	}
}

// function addHtmlToBodyIfExistInResponse(response) {
// 	if (typeof window !== "undefined" && response.data?.html) {
// 		document.body.innerHTML = response.data.html
// 	}
// }

export const MSMChatRequest = async (name, payload = {}) => {
	const endpoint = msm_api.endpoint?.[name] || null;
	if (!endpoint) {
	  return null;
	}
	const abortController = new AbortController();
	try {
	  const response = await axios(
		{
		  url: `${msm_api.base_url_chat}${endpoint.url}`, // base_url_chat invece che base_url
		  method: endpoint.method,
		  data: payload,
		  headers: msm_api.headers,
		  ...auth,
		},
		{ signal: abortController.signal }
	  );
	  pushDatalayerEventIfExistInResponse(response);
	//   console.log("this is response.data diretto di MSMChatRequest:")
	//   console.log(response.data)
	  return response.data;
	} catch (error) {
	  return null;
	}
  };
  

function pushDatalayerEventIfExistInResponse(response) {
	if (
		typeof window !== "undefined" &&
		window.dataLayer &&
		window.dataLayer.push &&
		(response.data?.dataLayerGa4 || response.data?.dataLayerGtm)
	) {
		if (response.data?.dataLayerGa4) {
			window.dataLayer.push(response.data.dataLayerGa4)
		}
		if (response.data?.dataLayerGtm) {
			window.dataLayer.push(response.data.dataLayerGtm)
		}
	}
}
