// extracted by mini-css-extract-plugin
export var activeMenuItem = "menu-desktop-module--activeMenuItem--22d17";
export var checked = "menu-desktop-module--checked--3caf5";
export var fixedLogo = "menu-desktop-module--fixedLogo--ea3da";
export var fixedNumber = "menu-desktop-module--fixedNumber--e1841";
export var megaMenu = "menu-desktop-module--mega-menu--9dad6";
export var megaMenuItemsWrapper = "menu-desktop-module--mega-menu-items-wrapper--56d25";
export var megaMenuRow = "menu-desktop-module--mega-menu-row--73991";
export var megaMenuScrolled = "menu-desktop-module--mega-menu-scrolled--1e189";
export var megaMenuTitleWrapper = "menu-desktop-module--mega-menu-title-wrapper--ada1b";
export var menuChatMutuo = "menu-desktop-module--menuChatMutuo--662ef";
export var menuDesktop = "menu-desktop-module--menu-desktop--a97ea";
export var menuDesktopBrandLogo = "menu-desktop-module--menu-desktop-brand-logo--a3d30";
export var menuDesktopContainer = "menu-desktop-module--menuDesktopContainer--04fbf";
export var menuDesktopContainerChatMutuo = "menu-desktop-module--menuDesktopContainerChatMutuo--9e1e3";
export var menuDesktopExtraLogo = "menu-desktop-module--menu-desktop-extra-logo--35b79";
export var menuDesktopHideMobile = "menu-desktop-module--menu-desktop-hide-mobile--f1dea";
export var menuDesktopLogoChatMutuo = "menu-desktop-module--menuDesktopLogoChatMutuo--8428f";
export var menuDesktopMenu = "menu-desktop-module--menu-desktop-menu--8a88e";
export var menuDesktopMenuScrolled = "menu-desktop-module--menu-desktop-menu-scrolled--49911";
export var menuDesktopScrolled = "menu-desktop-module--menu-desktop-scrolled--e0f1e";
export var menuMobileChatMutuo = "menu-desktop-module--menuMobileChatMutuo--b5a35";
export var modalBody = "menu-desktop-module--modal-body--8b5ef";
export var modalForm = "menu-desktop-module--modal-form--202b2";
export var unchecked = "menu-desktop-module--unchecked--15821";