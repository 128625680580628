// extracted by mini-css-extract-plugin
export var calcolatore = "native-select-module--calcolatore--71a57";
export var checked = "native-select-module--checked--49a5f";
export var fieldError = "native-select-module--field-error--7efc1";
export var fieldValid = "native-select-module--field-valid--d7241";
export var form = "native-select-module--form--32386";
export var nativeSelect = "native-select-module--native-select--ed641";
export var preventivo = "native-select-module--preventivo--12e15";
export var ricercatore = "native-select-module--ricercatore--02aac";
export var ricercatoreVerde = "native-select-module--ricercatoreVerde--13013";
export var unchecked = "native-select-module--unchecked--e4afe";
export var valutazione = "native-select-module--valutazione--b33f7";
export var verifica = "native-select-module--verifica--a922c";