import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Modal } from "react-bootstrap"

import * as modalServiceStyles from "./modal-service.module.scss"

export default function ModalService({
	show,
	setShow,
	title,
	children,
	scrollable = true,
	fullscreen = false,
	size = "lg",
	modalBodyClassName = "",
	onHideExternal = null,
}) {
	const bodyRef = useRef(null)
	const [isOverflow, setIsOverflow] = useState(false)
	useEffect(() => {
		const element = bodyRef.current
		if (!element) return
		const rect = element.getBoundingClientRect()
		if (rect.height < element.scrollHeight) {
			setIsOverflow(true)
		} else {
			setIsOverflow(false)
		}
	}, [bodyRef, children])
	return (
		<Modal
			show={show}
			onHide={() => {
				setShow(false)
				if (onHideExternal && typeof onHideExternal === "function")
					onHideExternal()
			}}
			scrollable={scrollable}
			fullscreen={fullscreen}
			size={size}
			centered
			className={modalServiceStyles.modalService}
			backdropClassName={modalServiceStyles.backdrop}
			contentClassName={modalServiceStyles.modalServiceContent}
		>
			<Modal.Header
				closeButton
				className={modalServiceStyles.modalServiceHeader}
			>
				<Modal.Title className={modalServiceStyles.modalServiceHeaderTitle}>
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				ref={bodyRef}
				className={classNames(modalBodyClassName, {
					[modalServiceStyles.modalServiceBodyOverflow]: isOverflow,
				})}
			>
				{children}
			</Modal.Body>
		</Modal>
	)
}
