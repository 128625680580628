import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Image from "../components/Image"
import * as footerStyles from "../sass/layout/footer.module.scss"
import UniversalLink from "./UniversalLink"

const Footer = () => {
	const {
		FOOTER_MENU_COL_1,
		FOOTER_MENU_COL_2,
		FOOTER_MENU_COL_3,
		FOOTER_MENU_COL_4,
		wp: {
			acfOptionsFooter: {
				footer: {
					top: { footerLogo },
					bottom: { footerTxtBottom },
				},
			},
			acfOptionsSocials: {
				socials: { socials },
			},
			acfOptionsTema: {
				opzioniTema: {
					generali: { numeroVerde },
				},
			},
		},
	} = useStaticQuery(graphql`
		{
			FOOTER_MENU_COL_1: wpMenu(locations: { eq: FOOTER_MENU_COL_1 }) {
				id
				count
				menuItems {
					nodes {
						label
						title
						id
						path
						cssClasses
						target
						order
						parentId
						# acfHeaderNavigation {
						# 	sectionTitle
						# }
					}
				}
				acfFooterMenu {
					titoloColonna
				}
			}
			FOOTER_MENU_COL_2: wpMenu(locations: { eq: FOOTER_MENU_COL_2 }) {
				id
				count
				menuItems {
					nodes {
						label
						title
						id
						path
						cssClasses
						target
						order
						parentId
						# acfHeaderNavigation {
						# 	sectionTitle
						# }
					}
				}
				acfFooterMenu {
					titoloColonna
				}
			}
			FOOTER_MENU_COL_3: wpMenu(locations: { eq: FOOTER_MENU_COL_3 }) {
				id
				count
				menuItems {
					nodes {
						label
						title
						id
						path
						cssClasses
						target
						order
						parentId
						# acfHeaderNavigation {
					# 	sectionTitle
					# }
					}
				}
				acfFooterMenu {
					titoloColonna
				}
			}
			FOOTER_MENU_COL_4: wpMenu(locations: { eq: FOOTER_MENU_COL_4 }) {
				id
				count
				menuItems {
					nodes {
						label
						title
						id
						path
						cssClasses
						target
						order
						parentId
						# acfHeaderNavigation {
						# 	sectionTitle
						# }
					}
				}
				acfFooterMenu {
					titoloColonna
				}
			}
			wp {
				acfOptionsSocials {
					socials {
						socials: social {
							link
							icona {
								altText
								localFile {
									extension
									childImageSharp {
										gatsbyImageData
									}
									publicURL
								}
								width
								height
								title
							}
						}
					}
				}
				acfOptionsTema {
					opzioniTema {
						generali {
							numeroVerde
						}
					}
				}
				acfOptionsFooter {
					footer {
						top {
							footerLogo: logo {
								altText
								localFile {
									extension
									childImageSharp {
										gatsbyImageData
									}
									publicURL
								}
								width
								height
								title
							}
						}
						bottom {
							footerTxtBottom: testo
						}
					}
				}
			}
		}
	`)

	const filledMenusColArray = [
		FOOTER_MENU_COL_1,
		FOOTER_MENU_COL_2,
		FOOTER_MENU_COL_3,
		FOOTER_MENU_COL_4,
	].reduce((acc, menu) => {
		if (menu?.count && menu?.count > 0) {
			acc.push(menu)
		}
		return acc
	}, [])
	const filledMenusColCount = filledMenusColArray.length
	const filledMenusColSizeMD = () => {
		switch (filledMenusColCount) {
			case 1:
				return 12
			case 2:
				return 6
			case 3:
				return 6
			case 4:
				return 6
			default:
				return 12
		}
	}
	const filledMenusColSizeLG = 12 / filledMenusColCount

	function handleIubenda (){
		document.querySelector('.iubenda-cs-preferences-link').click()
	}

	return (
		<footer className={footerStyles.footer}>
			<Container>
				<div className={footerStyles.footerTop}>
					<Image data={footerLogo} className={footerStyles.footerTopLogo} />
					{numeroVerde.length && (
						<a
							href={`tel:${numeroVerde}`}
							rel="noreferrer"
							className={footerStyles.footerTopNVerde}
						>
							<img
								src="/icon-phone.svg"
								alt="cornetta telefonica"
								width="42"
								height="42"
							/>
							<div>
								<p>Numero verde</p>
								<p>{numeroVerde}</p>
							</div>
						</a>
					)}
					{socials.length && (
						<div
							className={`${footerStyles.footerSocials} ${footerStyles.footerSocialsTop}`}
						>
							{socials.map((social, i) => {
								return (
									<a
										key={i}
										href={social.link}
										tabIndex="0"
										target="_blank"
										rel="noreferrer"
									>
										<Image data={social.icona} />
									</a>
								)
							})}
						</div>
					)}
				</div>

				{filledMenusColCount > 0 && (
					<Row className={footerStyles.footerMenus}>
						{filledMenusColArray.map(menu => {
							return (
								<Col
									md={filledMenusColSizeMD()}
									lg={filledMenusColSizeLG}
									key={menu.id}
								>
									{menu?.acfFooterMenu?.titoloColonna && (
										<h5>{menu.acfFooterMenu.titoloColonna}</h5>
									)}
									<nav>
										<ul>
											{menu.menuItems.nodes.map(menuItem => {
												const { target, path, label, id, cssClasses } = menuItem
												// console.log("this is cssClasses from menuItems 0:")
												// console.log(String(cssClasses[0]))
												// let iubendaData = String(cssClasses[0]) === 'iubenda-cs-preferences-link' ? '1' : ""
												return (
													<li key={id}>
														<UniversalLink
															to={path}
															target={target}
															className={classNames(cssClasses)}
															// iubenda={iubendaData}
														>
															{label}
														</UniversalLink>
													</li>
												)
											})}
											{menu.acfFooterMenu.titoloColonna ==="Il Servizio" && (
												<li>
													<div onClick={handleIubenda}>
														PREFERENCE PRIVACY
													</div>
												</li>
											)}
											
										</ul>
									</nav>
								</Col>
							)
						})}
					</Row>
				)}

											

											

				<div className={footerStyles.footerBottom}>
					{socials.length > 0 && (
						<div
							className={`${footerStyles.footerSocials} ${footerStyles.footerSocialsBottom}`}
						>
							{socials.map((social, i) => {
								return (
									<a
										key={i}
										href={social.link}
										tabIndex="0"
										target="_blank"
										rel="noreferrer"
									>
										<Image data={social.icona} />
									</a>
								)
							})}
						</div>
					)}
					{footerTxtBottom && (
						<p dangerouslySetInnerHTML={{ __html: footerTxtBottom }} />
					)}
				</div>
			</Container>
		</footer>
	)
}

export default Footer
