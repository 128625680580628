import classNames from "classnames"
import { motion } from "framer-motion"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { useStaticQueryDataChatMutuo } from "../../query/useStaticQueryDataChatMutuo"
import { useStaticQueryDataMenuDesktop } from "../../query/useStaticQueryDataMenuDesktop"
import {
	HIDE_MODAL_CONTATTACI,
	IS_MEGA_MENU_OPEN,
	SHOW_MODAL_CONTATTACI,
} from "../../state/actions/ui.actions"
import { getContattaciShow } from "../../state/selectors/ui.selector"
import { flatMenuListToHierarchical, removePTag } from "../../utils/helpers"
import { useDesktopMediaQuery, useOnlyLgMediaQuery } from "../ComponentUtils/Responsive"
import Forms from "../FlexibleComponents/Forms"
import Image from "../Image"
import ModalService from "../ModalService"
import UniversalLink from "../UniversalLink"
import * as menuDesktopStyles from "./menu-desktop.module.scss"
import { useLocation } from "@reach/router"


const numeroVerdeVariants = {
	open: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.4,
		},
	},
	close: {
		opacity: 0,
		y: -34,
		transition: {
			duration: 0.4,
		},
	},
}

const logoVariants = {
	open: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.4,
		},
	},
	close: {
		opacity: 0,
		y: -30,
		transition: {
			duration: 0.4,
		},
	},
}

const MenuDesktop = ({
	isHpTemplate = false,
	ligthVersion = false,
	isChatMutuoTemplate = false,
}) => {
	const dispatch = useDispatch()
	const isDesktop = useDesktopMediaQuery()
	const [expanded, setExpanded] = useState("")
	const [isShrunk, setShrunk] = useState(false)
	const showModalContattaci = useSelector(getContattaciShow)

	const {
		logo,
		numeroVerde,
		ctaNumeroVerde,
		logoMenu,
		menu,
	} = useStaticQueryDataMenuDesktop()

	const {
		chatMutuo: { logo: logoChatMutuo },
	} = useStaticQueryDataChatMutuo()

	useEffect(() => {
		if (!isDesktop) {
			dispatch(HIDE_MODAL_CONTATTACI())
		}
	}, [isDesktop])



	

	// useEffect(() => {
	// 	const onScroll = () => {
	// 		setExpanded(null)
	// 		setShrunk(isShrunk => {
	// 			if (
	// 				!isShrunk &&
	// 				(document.body.scrollTop > 25 ||
	// 					document.documentElement.scrollTop > 25)
	// 			) {
	// 				return true
	// 			}
	// 			if (
	// 				isShrunk &&
	// 				document.body.scrollTop < 100 &&
	// 				document.documentElement.scrollTop < 100
	// 			) {
	// 				return false
	// 			}
	// 			return isShrunk
	// 		})
	// 	}
	// 	dispatch(HIDE_MODAL_CONTATTACI())
	// 	window.addEventListener("scroll", onScroll)
	// 	return () => window.removeEventListener("scroll", onScroll)
	// }, [])
	const location = useLocation();
	useEffect(() => {
        const onScroll = () => {
            setShrunk(isShrunk => {
                if (
                    !isShrunk &&
                    (document.body.scrollTop > 25 ||
                        document.documentElement.scrollTop > 25)
                ) {
                    return true;
                }
                if (
                    isShrunk &&
                    document.body.scrollTop < 100 &&
                    document.documentElement.scrollTop < 100
                ) {
                    return false;
                }
                return isShrunk;
            });
            
            // se lo scroll è attivo, e siamo nella stessa pagina, si attiva setExpanded a null
            if (!location.key) { // questo valore in negativo ricontrolla che non ci siamo spostati di pagina
                setExpanded(null); 
            }
        };

        // fuori di onScroll, questo se attiva se cambiamo pagina (dependency location.key)
        setExpanded(null);

        dispatch(HIDE_MODAL_CONTATTACI());
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [location.key]); 
	

	const hierarchicalList = flatMenuListToHierarchical(menu.nodes) || []
	
	const isOnlyLg = useOnlyLgMediaQuery()

	const MenuConditional = ({templateName, label, novitaClass, etichetta = false , testoEtichetta = ""}) => {

		// console.log("this is etichetta:", etichetta)
		// console.log("this is testoEtichetta:", testoEtichetta)
		
		if (templateName === "ChatMutuo") {
			return (
				<>
				<span>
					<span
						style={{
							color: "#72B043",
						}}
					>
						Chat 
					</span>
					<span
						style={{
							color: "#468A4C",
						}}
					>
						Mutuo
					</span>
				</span>
					<sup
						
						style={{
							color: "#F37324",
							fontSize: ".5625rem",
							fontWeight: "bold",
							
						}}
					>
						BETA
					</sup>
				</>
			)


		} else if 		
		//checkbox acf
		(novitaClass){
				return (
					<>
						<span
							style={{
								paddingRight:"5px"
							}}
						>
							<img
								src="/megafono.svg"
								alt="megafono icon"
								width={15}
								height={15}
							/>
						</span>
						<span
							// style={{
							// 	fontWeight: "bold",
							// }}
						>
							{label} 
							{/* NOVITÀ */}
						</span>
					</>
				)
		}
		else if (etichetta && testoEtichetta && isOnlyLg){
			return (
				<span> {testoEtichetta} </span>
			)
		}
		
		else {
			return (
				<span> {label} </span>
			)
		}

	}

	// const location = useLocation();
	const [hoverItem, setHoverItem ] = useState(null)
	const [isMenuChildOpen, setMenuChildOpen] = useState(null);

	
	

	return (
		<header>
			<Navbar
				fixed="top"
				className={classNames(menuDesktopStyles.menuDesktop, {
					[menuDesktopStyles.menuDesktopScrolled]: isShrunk,
					[menuDesktopStyles.menuDesktopHideMobile]: isHpTemplate,
					[menuDesktopStyles.menuChatMutuo]: isChatMutuoTemplate,
				})}
			>
				<Container
					className={classNames(menuDesktopStyles.menuDesktopContainer, {
						[menuDesktopStyles.menuDesktopContainerChatMutuo]: isChatMutuoTemplate,
					})}
				>
					<Navbar.Brand href="/">
						<Image
							data={logo}
							className={classNames(menuDesktopStyles.menuDesktopBrandLogo, {
								[menuDesktopStyles.menuDesktopLogoChatMutuo]: isChatMutuoTemplate,
							})}
						/>
						{isChatMutuoTemplate && (
							<Image
								data={logoChatMutuo}
								className={classNames(menuDesktopStyles.menuDesktopLogo, {
									[menuDesktopStyles.menuMobileChatMutuo]: isChatMutuoTemplate,
								})}
							/>
						)}
					</Navbar.Brand>
					{hierarchicalList?.length > 0 && !ligthVersion && (
						<Nav
							className={classNames(menuDesktopStyles.menuDesktopMenu, {
								[menuDesktopStyles.menuDesktopMenuScrolled]: isShrunk,
								[menuDesktopStyles.menuDesktopMenuHpTemplate]: isHpTemplate,
								

							})}
						>
							{hierarchicalList.map(menuItem => {
								const { id, target, path, label, children } = menuItem
								const templateName =
									menuItem?.connectedNode?.node?.template?.templateName || ""
								const hasMenuNovita =
									menuItem?.acfMenuNovita?.menuNovita
								const hasEtichettaCorta = 
									menuItem?.acfEtichettaCorta?.etichettaCorta
								const nuovaEtichetta = 
									menuItem?.acfEtichettaCorta?.nuovaEtichetta
								const isActive = location.pathname === path;
								
								return (
									<div key={id}>
										<Nav.Link
											as={UniversalLink}
											to={path}
											target={target}
											
											onMouseOver={() => {
												if (children.length > 0) {
													setExpanded(id)
													setHoverItem(id)
													setMenuChildOpen(id)
													

												} else {
													setExpanded(null)
													setHoverItem(id)
													setMenuChildOpen(null)
												
	
												}
											}}
											onMouseOut={()=>{
												setHoverItem(null)
											}}
											className={classNames({
												[menuDesktopStyles.activeMenuItem]: hoverItem === id || isActive || isMenuChildOpen === id , 
												
											})}
											style={{ padding: "0" }}
										>
											<div >
												<MenuConditional templateName={templateName} label={label} novitaClass={hasMenuNovita} etichetta={hasEtichettaCorta} testoEtichetta={nuovaEtichetta}  />
												
											</div>
											
											{/* {templateName === "ChatMutuo" ? (
												<>
													<span
														style={{
															color: "#72B043",
														}}
													>
														Chat 
													</span>
													<span
														style={{
															color: "#468A4C",
														}}
													>
														Mutuo
													</span>
													<sup
														style={{
															color: "#F37324",
															fontSize: ".5625rem",
															fontWeight: "bold",
														}}
													>
														BETA
													</sup>
												</>
											) : (
												label 
											)} */}
											
										</Nav.Link>
									</div>
								)
							})}
						</Nav>
					)}
					{numeroVerde && isHpTemplate && (
						<motion.button
							className={menuDesktopStyles.fixedNumber}
							variants={numeroVerdeVariants}
							initial="close"
							animate={isShrunk ? "close" : "open"}
							transition={{ duration: 0.4 }}
							onClick={() =>
								ctaNumeroVerde.popup
									? dispatch(SHOW_MODAL_CONTATTACI())
									: window.open(`tel:+39 ${numeroVerde}`, "_blank")
							}
						>
							{numeroVerde}
						</motion.button>
					)}
					{!isHpTemplate && logoMenu && (
						// <Image
						// 	data={logoMenu}
						// 	className={menuDesktopStyles.menuDesktopExtraLogo}
						// 	useImgTag
						// />
						<motion.div
							className={menuDesktopStyles.fixedLogo}
							variants={logoVariants}
							initial="close"
							animate={isShrunk ? "close" : "open"}
							transition={{ duration: 0.4 }}
						>
							<Image data={logoMenu} useImgTag />
							
						</motion.div>
					)}
				</Container>
			</Navbar>
			{hierarchicalList?.length > 0 &&
				!ligthVersion &&
				hierarchicalList?.map(menuItem => {
					const {
						children,
						id,
						// acfHeaderNavigation: { sectionTitle },
					} = menuItem
					if (children?.length > 0) {
						return (
							<motion.div
								onMouseLeave={() => {
									setTimeout(() => {
										setExpanded(null);
										setHoverItem(null);
										setMenuChildOpen(null);
									}, 200); 
								}}
								onAnimationComplete={e => {
									if (e === "close") {
										dispatch(IS_MEGA_MENU_OPEN(false))
									}
								}}
								onAnimationStart={e => {
									if (e === "open") {
										dispatch(IS_MEGA_MENU_OPEN(true))
										
									}
								}}
								initial="close"
								animate={expanded === id ? "open" : "close"}
								variants={{
									open: {
										display: "block",
										height: "auto",
									},
									close: {
										height: 0,
										transitionEnd: {
											display: "none",
										},
									},
								}}
								className={classNames(menuDesktopStyles.megaMenu, {
									[menuDesktopStyles.megaMenuScrolled]: isShrunk,
								})}
								key={`${id}-mega-menu`}
							>
								<Container>
									<Row className={menuDesktopStyles.megaMenuRow}>
										<Col lg={5}>
											<div className={menuDesktopStyles.megaMenuTitleWrapper}>
											
												<h2>
													
													{/* {parse(removePTag(sectionTitle))} */}
													{parse(removePTag(menuItem.label))}
													<img
														src="/megamenu.svg"
														alt="megamenu background icon"
														width={150}
														height={150}
													/>
												</h2>
											</div>
										</Col>
										<Col lg={7}>
											<ul className={menuDesktopStyles.megaMenuItemsWrapper}>
												{children.map(menuItem => {
													const { id, path, label } = menuItem
													return (
														<li key={id}>
														
															<UniversalLink to={path}>{label}</UniversalLink>
														</li>
													)
												})}
											</ul>
										</Col>
									</Row>
								</Container>
							</motion.div>
						)
					}
				})}
			<ModalService
				show={showModalContattaci}
				setShow={() => dispatch(HIDE_MODAL_CONTATTACI())}
				title={ctaNumeroVerde.popup.titolo}
				modalBodyClassName={menuDesktopStyles.modalBody}
			>
				<div className={menuDesktopStyles.modalForm}>
					<Forms
						noContainer
						layoutData={{
							fieldGroupName: "Forms",
							form: ctaNumeroVerde.popup.form,
							labelCta: "Invia",
							sottotitolo: ctaNumeroVerde.popup.sottotitolo,
							consenso: ctaNumeroVerde.popup.consenso,
						}}
					/>
				</div>
			</ModalService>
		</header>
	)
}

export default MenuDesktop
