// extracted by mini-css-extract-plugin
export var accordionBody = "service-bar-module--accordion-body--4f47f";
export var accordionButton = "service-bar-module--accordion-button--8db9c";
export var accordionFullLink = "service-bar-module--accordion-full-link--21168";
export var accordionItem = "service-bar-module--accordion-item--cc8dc";
export var btnNoStyle = "service-bar-module--btnNoStyle--0a5bf";
export var chatMutuoLogo = "service-bar-module--chatMutuoLogo--752c6";
export var checked = "service-bar-module--checked--6f961";
export var comeFunziona = "service-bar-module--comeFunziona--b73ce";
export var fixedIcon = "service-bar-module--fixed-icon--72fbc";
export var icon = "service-bar-module--icon--e50cc";
export var isActive = "service-bar-module--isActive--413ef";
export var isActive2 = "service-bar-module--isActive2--e9b03";
export var menuMobile = "service-bar-module--menu-mobile--baf84";
export var menuMobileClose = "service-bar-module--menu-mobile-close--162c7";
export var menuMobileMenu = "service-bar-module--menu-mobile-menu--79000";
export var menuMobileTop = "service-bar-module--menu-mobile-top--7bd98";
export var menuMobileTopNoPaddingY = "service-bar-module--menu-mobile-top-noPaddingY--91131";
export var menuMobileWrapper = "service-bar-module--menu-mobile-wrapper--484e7";
export var menuMobileWrapperNoPaddingX = "service-bar-module--menu-mobile-wrapper-noPaddingX--37b75";
export var serviceBar = "service-bar-module--service-bar--81ca9";
export var serviceBarMenuButton = "service-bar-module--service-bar-menu-button--15f1f";
export var testoSottoVoceDiMenu = "service-bar-module--testoSottoVoceDiMenu--2d795";
export var unchecked = "service-bar-module--unchecked--4d040";
export var wrapperTestoSottoVoceDiMenu = "service-bar-module--wrapperTestoSottoVoceDiMenu--b4a7e";