import finalita from "./finalita.json"
import nazioni from "./nazioni.json"
import province from "./province.json"
import tipo_tasso from "./tipo_tasso.json"

export const labelTipoPageHpTemplate = {
	HP: "Mutui On Line",
	APC: "Mutuo Under 36",
	SUR: "Surroga Mutuo",
}

export const labels = {
	labelTipoPageHpTemplate: labelTipoPageHpTemplate,
	tipoTasso: tipo_tasso,
	finalita: finalita,
	province: province,
	nazioni: nazioni,
}
