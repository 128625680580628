import { graphql, useStaticQuery } from "gatsby"

export const useStaticQueryDataMenuDesktop = () => {
	const {
		wp: {
			acfOptionsNavbar: {
				navbar: { logoMenu },
			},
			acfOptionsTema: {
				opzioniTema: {
					generali: { numeroVerde, logo },
				},
			},
			acfOptionsImpostazioniTemplateHp: {
				acfOptionsTemplateHP: { ctaNumeroVerde },
			},
		},
		menu,
	} = useStaticQuery(graphql`
		query headerQuery {
			wp {
				acfOptionsImpostazioniTemplateHp {
					acfOptionsTemplateHP {
						ctaNumeroVerde {
							popup {
								titolo
								sottotitolo
								form
								consenso {
									popup {
										testo
										titolo
									}
									label
								}
							}
						}
					}
				}
				acfOptionsTema {
					opzioniTema {
						generali {
							numeroVerde
							logo {
								altText
								localFile {
									publicURL
									extension
									childImageSharp {
										gatsbyImageData(quality: 100, layout: FULL_WIDTH)
									}
								}
								height
								width
								title
							}
						}
					}
				}
				acfOptionsNavbar {
					navbar {
						logoMenu {
							altText
							localFile {
								publicURL
								extension
								childImageSharp {
									gatsbyImageData(quality: 100, layout: FULL_WIDTH)
								}
							}
							height
							width
							title
						}
					}
				}
			}
			menu: allWpMenuItem(filter: { locations: { eq: MAIN_MENU } }) {
				nodes {
					label
					title
					id
					path
					target
					parentId
					order
					# acfHeaderNavigation {
					# 	sectionTitle
					# }
					connectedNode {
						node {
							... on WpPage {
								id
								template {
									templateName
								}
							}
						}
					}
					acfMenuNovita {	
						fieldGroupName
						menuNovita
      				}
					acfEtichettaCorta{	
					fieldGroupName
					nuovaEtichetta
					etichettaCorta		
      				}
				}
			}
		}
	`)

	return {
		logo,
		numeroVerde,
		ctaNumeroVerde,
		logoMenu,
		menu,
	}
}
