import { createReducer } from "@reduxjs/toolkit"

import { labels } from "../../utils/labels"
import { ADD_MUTUI_BY_ID } from "../actions/requests.actions"
import { SORT_MUTUI_BY_ID } from "../actions/ui.actions"
import { FETCH_MUTUI_BY_ID } from "../thunk/mutui.thunk"

export const requestsReducer = createReducer(
	{
		researcher: {},
	},
	builder =>
		builder
			.addCase(SORT_MUTUI_BY_ID, (state, action) => {
				const { id, sortBy } = action.payload
				if (
					state.researcher[id] &&
					state.researcher[id]?.OutputMutui?.length > 0
				) {
					const sortedMutuiList = [...state.researcher[id].OutputMutui].sort(
						(a, b) => {
							switch (sortBy) {
								case "RATA":
									return a.Rata - b.Rata
								case "TAEG":
									return a.Taeg - b.Taeg
								case "FILIALE":
									return a.TipoCanale.localeCompare(b.TipoCanale)
								case "ONLINE":
									return b.TipoCanale.localeCompare(a.TipoCanale)
								default:
									return 0
							}
						}
					)
					state.researcher = {
						...state.researcher,
						[id]: {
							...state.researcher[id],
							OutputMutui: sortedMutuiList,
						},
					}
				}
			})
			.addCase(FETCH_MUTUI_BY_ID.pending, (state, action) => {
				state.researcher = {
					...state.researcher,
					[action.meta.arg.id]: {
						loading: true,
						error: "",
					},
				}
			})
			.addCase(FETCH_MUTUI_BY_ID.rejected, (state, action) => {
				state.researcher = {
					...state.researcher,
					[action.meta.arg.id]: {
						loading: false,
						error: action.payload.error,
					},
				}
			})
			.addCase(ADD_MUTUI_BY_ID, (state, { payload }) => {
				state.researcher = {
					...state.researcher,
					...payload,
				}
			})
			.addCase(FETCH_MUTUI_BY_ID.fulfilled, (state, action) => {
				if (action.payload?.OutputMutui.length > 0) {
					let breadcrumbArray = [
						{
							anchor: "FinalitaField",
							label: `${labels.finalita[
								action.payload.Finalita
							].toUpperCase()}`,
						},
						{
							anchor: "ImportoMutuoField",
							label: `€ ${new Intl.NumberFormat("it-IT", {
								currency: "EUR",
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}).format(action.payload.ImportoMutuo)}`,
						},
						{
							anchor: "DurataField",
							label: `${action.payload.Durata} ANNI`,
						},
					]
					if (action.meta.arg.type !== "BANCA") {
						breadcrumbArray.splice(2, 0, {
							anchor: "TipoTassoField",
							label: `TASSO ${labels.tipoTasso[
								action.payload.TipoTasso
							].toUpperCase()}`,
						})
					}
					state.researcher = {
						...state.researcher,
						[action.meta.arg.id]: {
							loading: false,
							error: "",
							breadcrumb: breadcrumbArray,
							params: {
								ImportoMutuo: action.payload.ImportoMutuo,
								ValoreImmobile: action.payload.ValoreImmobile,
								Durata: action.payload.Durata,
								TipoTasso: action.payload.TipoTasso,
								Finalita: action.payload.Finalita,
								ProvinciaImmobile: action.payload.Provincia,
								ProvinciaDomicilio: action.payload.Provincia,
								EtaRichiedente: action.payload.EtaRichiedente,
								RedditoMensile: action.payload.RedditoMensile,
								ClasseEnergetica: action.payload.ClasseEnergetica,
							},
							...action.payload,
						},
					}
				}
			})
)
