import { createSelector } from "@reduxjs/toolkit"
import _ from "lodash"

export const getMutuoFattibilitaShow = createSelector(
	state => state.ui.mutuo.fattibilita.show || false,
	state => state
)

export const getMutuoSelectedShow = createSelector(
	state => state.ui.mutuo.selected.show || false,
	state => state
)

export const getComparatorShow = createSelector(
	state => state.ui.comparator.show || false,
	state => state
)

export const getContattaciShow = createSelector(
	state => state.ui.contattaci.show || false,
	state => state
)
