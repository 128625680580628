// extracted by mini-css-extract-plugin
export var btnOrangeEditor = "come-funziona-module--btn-orange-editor--65950";
export var btnOrangeOutlineEditor = "come-funziona-module--btn-orange-outline-editor--dfa3f";
export var btnOrangeSimpleEditor = "come-funziona-module--btn-orange-simple-editor--3bd96";
export var btnOrangeSimpleOutlineEditor = "come-funziona-module--btn-orange-simple-outline-editor--fc48d";
export var checked = "come-funziona-module--checked--3e070";
export var comeFunziona = "come-funziona-module--comeFunziona--fd715";
export var comeFunziona__close = "come-funziona-module--comeFunziona__close--2c278";
export var comeFunziona__container = "come-funziona-module--comeFunziona__container--0a03b";
export var comeFunziona__editor = "come-funziona-module--comeFunziona__editor--87219";
export var comeFunziona__loader = "come-funziona-module--comeFunziona__loader--74e93";
export var comeFunziona__orange = "come-funziona-module--comeFunziona__orange--7c184";
export var comeFunziona__padding = "come-funziona-module--comeFunziona__padding--a4c1f";
export var comeFunziona__row = "come-funziona-module--comeFunziona__row--58810";
export var comeFunziona__row__even = "come-funziona-module--comeFunziona__row__even--8b05a";
export var comeFunziona__show = "come-funziona-module--comeFunziona__show--2e4eb";
export var comeFunziona__video = "come-funziona-module--comeFunziona__video--c1f75";
export var comeFunziona__video_col = "come-funziona-module--comeFunziona__video_col--aac89";
export var comeFunziona__video_container = "come-funziona-module--comeFunziona__video_container--60bd7";
export var comeFunziona__video_text_col = "come-funziona-module--comeFunziona__video_text_col--85fce";
export var comeFunziona__yellow = "come-funziona-module--comeFunziona__yellow--0c437";
export var editorButtonOrange = "come-funziona-module--editorButtonOrange--133bd";
export var editorButtonOrange2 = "come-funziona-module--editorButtonOrange-2--dc1ab";
export var gallery = "come-funziona-module--gallery--dde4e";
export var large = "come-funziona-module--large--71ae1";
export var linkOrangeEditor = "come-funziona-module--link-orange-editor--68525";
export var medium = "come-funziona-module--medium--83310";
export var ratio = "come-funziona-module--ratio--ec690";
export var small = "come-funziona-module--small--df13c";
export var unchecked = "come-funziona-module--unchecked--2d5b3";