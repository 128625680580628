import { graphql, useStaticQuery } from "gatsby"

export const useStaticQueryDataComeFunziona = () => {
	const {
		wp: { acfOptionsComeFunziona },
	} = useStaticQuery(graphql`
		query {
			wp {
				acfOptionsComeFunziona {
					acfOptionsComeFunziona {
						video {
							youtubeUrl
							titolo
							testo
						}
					}
				}
			}
		}
	`)
	return { acfOptionsComeFunziona } || {}
}
