import { createAsyncThunk } from "@reduxjs/toolkit"

import { MSMRequest } from "../../api/msm"

export const FETCH_PREVENTIVO = createAsyncThunk(
	"requests/mutuo/selected/update/fetch",
	async (_, thunkAPI) => {
		try {
			const state = thunkAPI.getState()
			const data = {
				...state.user.selected.ricercaApplicata,
				CodiceMutuo: state.user.selected.CodiceMutuo,
			}
			const response = await MSMRequest("RicercaMutuo", data)
			return response
		} catch (error) {
			return thunkAPI.rejectWithValue({ error: error.message })
		}
	}
)
